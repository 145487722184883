import GatsbyImage from "gatsby-image"
import React, { FC, useContext } from "react"
import { StructuredText } from "react-datocms"
import Slider from "react-slick"
import { GlobalContext } from "~/GlobalProvider"
import Link from "~/components/atoms/Link/Link"

import * as css from "./Gallery.module.scss"
import WithBackground from "../../../WithBackground/WithBackground"

type GalleryProps = {
  aboveText: any
  description: any
  images: Array<any>
  actionText?: string
  actionUrl?: string
}

const Gallery: FC<GalleryProps> = ({ aboveText, description, images, actionText, actionUrl }) => {
  const { setModalOpen, setModalContents, setModalName } =
    useContext(GlobalContext)

  const openGallery = (imageIndex: number = 0) => {
    const settings = {
      infinite: true,
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: imageIndex,
    }

    setModalContents(
      <Slider {...settings}>
        {images?.length &&
          images.map(({ fluid, title }, index) => (
            <GatsbyImage fluid={fluid} key={index} title={title} />
          ))}
      </Slider>
    )

    setModalName("Gallery")

    setModalOpen(true)
  }

  return (
    <WithBackground>
      <div className={css.wrapper}>
        <div className={css.aboveText}>
          <StructuredText data={aboveText} />
        </div>
        <div className={css.columns}>
          {images?.length > 0 && (
            <div className={css.gallery}>
              <div className={css.firstImage} onClick={() => openGallery()}>
                <GatsbyImage fluid={images[0].fluid} title={images[0].title} />
              </div>
              {images?.length > 1 && (
                <div className={css.imageThumbnails}>
                  {images.slice(1, 4).map(({ fluid, title }, index) => (
                    <div
                      className={css.thumbnail}
                      key={index}
                      onClick={() => openGallery(index + 1)}
                    >
                      <GatsbyImage fluid={fluid} title={title} />
                    </div>
                  ))}
                </div>
              )}
              <div className={css.openGallery}>
                <svg
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="27"
                    cy="27"
                    r="26"
                    fill="white"
                    stroke="#537495"
                    stroke-width="2"
                  />
                  <path
                    d="M24.5 38.25V35.75H20.0125L25.6375 30.125L23.875 28.3625L18.25 33.9875V29.5H15.75V38.25H24.5ZM30.125 25.6375L35.75 20.0125V24.5H38.25V15.75H29.5V18.25H33.9875L28.3625 23.875L30.125 25.6375Z"
                    fill="#537495"
                  />
                </svg>
              </div>
            </div>
          )}
          <div className={css.description}>
            <div className={css.descriptionInner}>
              <StructuredText data={description} />
            </div>
            {actionText && actionUrl && (
              <div className={css.descriptionAction}>
                {(() => {
                  const LinkComp = ({ children }) => {
                    if (actionUrl[0] === '/') {
                      return <Link className={css.linkWithIcon} to={actionUrl}>{children}</Link>
                    }

                    return <a className={css.linkWithIcon} href={actionUrl} target="_blank">{children}</a>
                  }

                  return (
                    <LinkComp>
                      <span>{actionText}</span>
                      <svg
                        width="45"
                        height="44"
                        viewBox="0 0 45 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M31.4266 10.4308L29.4821 12.3753L37.7318 20.625H1.875V23.3751H37.7316L29.4821 31.6246L31.4266 33.5691L42.9959 22L31.4266 10.4308Z"
                          fill="#303E4B"
                        />
                      </svg>
                    </LinkComp>
                  )
                })()}
              </div>
            )}
          </div>
        </div>
      </div>
    </WithBackground>
  )
}

export default Gallery
