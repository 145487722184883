import GatsbyImage from "gatsby-image"
import React, { FC } from "react"
import { StructuredText } from "react-datocms"
import WithBackground from "../../../WithBackground/WithBackground"

import * as css from "./Info.module.scss"

type InfoProps = {
  infoPieces: Array<{
    heading: string
    description: any
    image: any
  }>
}

const Info: FC<InfoProps> = ({ infoPieces }) => {
  if (!infoPieces?.length) {
    return null
  }

  return (
    <WithBackground className={css.wrapper}>
      <div className={css.inner}>
        {infoPieces.map(({ heading, description, image }, index) => (
          <div className={css.infoItem} key={index}>
            <div className={css.image}>
              <GatsbyImage fluid={image.fluid} />
            </div>
            <h4 className={css.heading}>{heading}</h4>
            <div className={css.description}>
              <StructuredText data={description} />
            </div>
          </div>
        ))}
      </div>
    </WithBackground>
  )
}

export default Info
