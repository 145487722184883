import React, { FC } from 'react'
import { StructuredText } from 'react-datocms'
import * as css from './Text.module.scss'

const Text: FC<any> = ({text}) => (
  <div className={css.wrapper}>
    <div className={css.inner}>
      <StructuredText data={text} />
    </div>
  </div>
)

export default Text