import React, { FC } from "react"
import { StructuredText } from "react-datocms"

import * as css from "./Quotes.module.scss"

type QuotesProps = {
  heading: string
  quotes: Array<{
    author: string
    quote: any
  }>
}

const Quotes: FC<QuotesProps> = ({ heading, quotes }) => {
  if (!quotes.length) {
    return null
  }

  return (
    <div className={css.wrapper}>
      <div className={css.inner}>
        <h3 className={css.heading}>
          <em>{heading}</em>
        </h3>
        <div className={css.quotes}>
          {quotes.map(({ author, quote }, index) => (
            <div className={css.quote} key={index}>
              <svg
                width="134"
                height="109"
                viewBox="0 0 134 109"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M134 11.3C121.733 20.6333 112.933 29.5667 107.6 38.1C102.533 46.3667 100 55.5667 100 65.7C103.467 63.5667 107.467 62.5 112 62.5C118.133 62.5 123.067 64.6333 126.8 68.9C130.8 72.9 132.8 78.1 132.8 84.5C132.8 91.7 130.267 97.5667 125.2 102.1C120.4 106.633 113.867 108.9 105.6 108.9C86.6667 108.9 77.2 96.3667 77.2 71.3C77.2 64.6333 78 58.5 79.6 52.9C81.2 47.0333 83.7334 41.4333 87.2 36.1C90.9334 30.5 95.8667 24.9 102 19.3C108.133 13.4333 115.733 7.16666 124.8 0.5L134 11.3ZM57.2 11.3C44.9334 20.6333 36.1334 29.5667 30.8 38.1C25.7334 46.3667 23.2 55.5667 23.2 65.7C26.6667 63.5667 30.6667 62.5 35.2 62.5C41.3334 62.5 46.2667 64.6333 50 68.9C54 72.9 56 78.1 56 84.5C56 91.7 53.4667 97.5667 48.4 102.1C43.6 106.633 37.0667 108.9 28.8 108.9C9.86669 108.9 0.400024 96.3667 0.400024 71.3C0.400024 64.6333 1.20002 58.5 2.80002 52.9C4.40002 47.0333 6.93336 41.4333 10.4 36.1C14.1334 30.5 19.0667 24.9 25.2 19.3C31.3334 13.4333 38.9334 7.16666 48 0.5L57.2 11.3Z"
                  fill="white"
                />
              </svg>

              <StructuredText data={quote} />
              <h4>{author}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Quotes
