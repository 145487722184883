import React, { FC, useContext, useState } from "react"
import { StructuredText } from "react-datocms"
import { GlobalContext } from "~/GlobalProvider"
import * as css from "./NewsletterSignup.module.scss"

type NewsletterSignupProps = {
  heading: string
  subheading: any
}

const NewsletterSignup: FC<NewsletterSignupProps> = ({
  heading,
  subheading,
}) => {
  const { companyInfo } = useContext(GlobalContext)
  const [isSubmitted, setIsSubmitted] = useState(false)
  return (
    <div className={css.wrapper}>
      <div className={css.inner}>
        <div className={css.copy}>
          <h2>{heading}</h2>
          <StructuredText data={subheading} />
        </div>
        <form
          className={css.emailField}
          name="newsletter-signup"
          method="POST"
          action={`https://formsubmit.co/${companyInfo.email}`}
          onSubmit={e => {
            setIsSubmitted(true)
          }}
        >
          <input type="text" name="_honey" style={{ display: "none" }} />
          <input type="hidden" name="_subject" value="Newsletter signup" />
          <input type="hidden" name="_captcha" value="false" />
          <input type="email" name="email" placeholder="Email address" />
          <button type="submit">
            <svg
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="27" cy="27" r="27" fill="#537495" />
              <path
                d="M24.1308 35.5225L26.57 37.9618L38.14 26.3918L26.57 14.8218L24.1308 17.261L31.5365 24.6667H15V28.1168H31.5365L24.1308 35.5225Z"
                fill="white"
              />
            </svg>
          </button>
          {isSubmitted && (
            <h4 style={{ position: "absolute", top: 0, width: "100%" }}>
              Thank you. We will be in touch
            </h4>
          )}
        </form>
      </div>
    </div>
  )
}

export default NewsletterSignup
