import React, { FC, useState } from "react"
import * as css from "./Block.module.scss"
import { useEffect } from "react"
import Gallery from "./components/Gallery/Gallery"
import Quotes from "./components/Quotes/Quotes"
import Info from "./components/Info/Info"
import Map from "./components/Map/Map"
import NewsletterSignup from "./components/NewsletterSignup/NewsletterSignup"
import ApartmentsList from "./components/ApartmentsList/ApartmentsList"
import Text from "./components/Text/Text"

const Block: FC<any> = props => {
  const [initialised, setInitialised] = useState(false)
  const [stateProps, setStateProps] = useState(props)

  const BlockType = {
    DatoCmsGallery: Gallery,
    DatoCmsQuoteBlock: Quotes,
    DatoCmsInfo: Info,
    DatoCmsMap: Map,
    DatoCmsNewsletterSignup: NewsletterSignup,
    DatoCmsApartmentsList: ApartmentsList,
    DatoCmsText: Text
  }

  useEffect(() => {
    setStateProps({
      ...props,
      className: `${props.className} ${css["colour"]}`,
    })

    setInitialised(true)
  }, [props])

  const { internal, children } = stateProps

  if (!internal?.type || !BlockType[internal?.type]) {
    return null
  }

  const RenderBlock: FC = ({ children }) => {
    const Block = BlockType[internal?.type]

    return <Block {...stateProps}>{children}</Block>
  }

  return <>{initialised && <RenderBlock>{children}</RenderBlock>}</>
}

export default Block
