import React, { FC } from "react"
import SEO from "~/utils/SEO"
import Footer from "~/components/molecules/Footer/Footer"
import Block from "~/components/atoms/Block"
import Hero from "~/components/molecules/Hero/Hero"
import Modal from "~/components/atoms/Modal/Modal"
import SideMenu from "../SideMenu/SideMenu"
import WithBackground from "../../atoms/WithBackground/WithBackground"

type PageProps = {
  name: string
  heading: string
  slug: string
  blocks: Array<any>
  description?: any
  image: any
  className?: string
  seo: any
  subheading?: string
  video?: any
  content: Array<any>
  showLargeLogo?: boolean
}

const Page: FC<PageProps> = ({
  heading,
  subheading,
  video,
  slug,
  content,
  image,
  className,
  seo,
  showLargeLogo
}) => (
    <>
      <main>
        <div className={`page ${className}`}>
          <SEO
            {...{
              title: seo?.title || heading,
              description: seo?.description || subheading || "",
              slug,
              image,
            }}
          />
          <Hero {...{ heading, subheading, video, image, showLargeLogo }} />
          <div className="page-content">
            {(content || []).map((contentItem, index) => {
              if (contentItem.internal.type === 'DatoCmsBlockWrapper') {
                return (
                  <WithBackground className="block-wrapper">
                    {contentItem.blocks.map((blockItem, bIndex) => (
                      <Block key={bIndex} {...blockItem} />
                    ))}
                  </WithBackground>
                )
              }

              return <Block key={index} {...contentItem} />
            })}
          </div>
        </div>
      </main>
      <Footer />
      <SideMenu />
      <Modal />
    </>
  )

export default Page
