import React, { FC } from "react"
import Page from "~/components/molecules/Page/Page"
import { GlobalProvider } from "../../GlobalProvider"
import { page } from "./index.module.scss"

export default ({
  pageContext,
}) => (
  <GlobalProvider>
    <Page
      {...pageContext}
      className={page}
    />
  </GlobalProvider>
)
