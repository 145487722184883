// extracted by mini-css-extract-plugin
export var aboveText = "Gallery-module--aboveText--1EkFr";
export var descriptionAction = "Gallery-module--descriptionAction--1uT0H";
export var wrapper = "Gallery-module--wrapper--24Sjo";
export var columns = "Gallery-module--columns--XfRlu";
export var gallery = "Gallery-module--gallery--806ga";
export var firstImage = "Gallery-module--firstImage--2hfYJ";
export var thumbnail = "Gallery-module--thumbnail--3BMfP";
export var imageThumbnails = "Gallery-module--imageThumbnails--k_vB0";
export var openGallery = "Gallery-module--openGallery--21uUA";
export var description = "Gallery-module--description--1ernq";
export var descriptionInner = "Gallery-module--descriptionInner--ey_Lt";